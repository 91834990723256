import React from 'react';
import { Modal, Tab } from 'semantic-ui-react'
import { User } from '../../../../common/types/user';
import './styles.scss';
import { connect } from 'react-redux';
import { Country } from '../../../../common/types/country';
import { addUserToGroup, fetchDevicesRoutine, removeDeviceRoutine, updateUserInGroup } from '../../../../redux/routines/index';
import UserInfoTab from "./children/user-info-tab/user-info-tab";
import DevicesTab from './children/devices-tab/devices-tab';
import { State } from "../../../../common/types/state";
import { DeviceDelete } from "../../../../common/types/deviceDelete";
import { Device } from "../../../../common/types/device";

interface IUsersModalProps {
    user: User;
    isNew: boolean;
    children: never[];
    updateUserInGroup: (user) => void;
    addUserToGroup: (user) => void;
    roles: any[];
    sports: string[];
    countries: Country[];
    competitions: string[];
    modalOpened: boolean;
    closeModal: () => void;
    group: any;
    getUserDevices: (userId: number) => any;
    removeUserDevice: (deleteModel: DeviceDelete) => any;
    userDevices: Device[]
}

interface IUsersModalState {
    user: User;
}

class UsersModal extends React.Component<IUsersModalProps, IUsersModalState> {
    constructor(props: IUsersModalProps) {
        super(props);
    }

    componentDidMount() {
        if (!this.props.isNew && this.props.user.id != 0) {
            const user = this.props.user;
            this.props.getUserDevices(user.id)
        }
    }

    createUser = (user: User) => {
        const { addUserToGroup } = this.props;
        user.groupId = this.props.group.singleGroup.id;
        addUserToGroup(user);
    }

    updateUser = (user: User) => {
        const { updateUserInGroup } = this.props;

        user.id = this.props.user!.id;
        updateUserInGroup(user);
    }

    saveUserInformation = (userInfo: User) => {
        this.props.isNew ? this.createUser(userInfo) : this.updateUser(userInfo);
        this.props.closeModal();
    }

    render() {
        const restrictions = this.props.group.singleGroup.restriction

        const panes = [
            {
                menuItem: 'Main Info',
                render: () => <UserInfoTab
                    user={this.props.user}
                    restrictions={restrictions}
                    saveInfo={this.saveUserInformation}
                />,
            },
            ... !this.props.isNew ? [{
                menuItem: 'User Devices',
                render: () => <DevicesTab
                    user={this.props.user as User}
                    userDevices={this.props.userDevices}
                    removeUserDevice={this.props.removeUserDevice}
                />,
            }] : []]

        return (
            <Modal
                open={this.props.modalOpened}
                onClose={this.props.closeModal}
                closeOnDimmerClick={true}
                style={{ left: '30%', top: '15%' }}>
                <Modal.Header>{this.props.isNew ? "Create new user" : "Update user"}</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Tab panes={panes} />
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        )
    }
}

const mapDispatchToProps = {
    addUserToGroup,
    updateUserInGroup,
    getUserDevices: fetchDevicesRoutine,
    removeUserDevice: removeDeviceRoutine
}

const mapStateToProps = (state: State) => {
    return {
        roles: state.roles,
        countries: state.countries,
        sports: state.sports,
        competitions: state.competitions,
        group: state.groups,
        userDevices: state.userDevices
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersModal);
